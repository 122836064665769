/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, Assignment, EventAttachment
} from '../dto';

import { PublicationType } from './publicationtype';

export interface Event_v1 extends DTO, DTOMethods<Event_v1> {
    eventType: number;
    subType: string;
    startDate: Date;
    endDate: Date;
    isCriticalPath: boolean;
    baselineStart: Date;
    baselineEnd: Date;
    createdDate: Date;
    completedDate: Date;
    hardEnd: Date;
    scheduledHours: number;
    scheduledHoursManual: number;
    progress: number;
    progressManual: number;
    progressOverride: boolean;
    isAllDay: boolean;
    status: string;
    priority: number;
    rank: number;
    rag: string;
    duration: number;
    durationType: string;
    durationUnit: string;
    startTimezone: string;
    endTimezone: string;
    location: string;
    recurrenceId: number;
    recurrenceRule: string;
    recurrenceException: string;
    isReadOnly: boolean;
    isPublished: boolean;
    publication?: EventAttachment;
    roots: DTORef[];
    children: DTORef[];
    files: EventAttachment[];
    dependencies: Attachment[];
    responses: Attachment[];
    resolvedUsers: Attachment[];
    resolvedAudience: DTORef[];

    /** Only comes from event_hierarchy_get  */
    assignments?: Attachment[]; // | Assignment[];
}

export const Event_v1: DTOMetaData & DTOClassMethods<Event_v1> = {
    _label:'Events',
    _dto: 'Event_v1',
    _type: 'event',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Sub type', prop: 'subType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Is critical path', prop: 'isCriticalPath', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Baseline start', prop: 'baselineStart', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Baseline end', prop: 'baselineEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Hard end', prop: 'hardEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Scheduled hours', prop: 'scheduledHours', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 8 },
        { label: 'Scheduled hours manual', prop: 'scheduledHoursManual', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 8 },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Progress manual', prop: 'progressManual', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Progress override', prop: 'progressOverride', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Is all day', prop: 'isAllDay', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration type', prop: 'durationType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Start timezone', prop: 'startTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'End timezone', prop: 'endTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Location', prop: 'location', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Recurrence id', prop: 'recurrenceId', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Recurrence rule', prop: 'recurrenceRule', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Recurrence exception', prop: 'recurrenceException', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Is published', prop: 'isPublished', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Publication type', prop: 'publicationType', hint: 'Publication type', type: 'enum', fixed: false, source: undefined, values: ["NEWS", "BLOG", "QUESTIONNAIRE"], default: PublicationType.NEWS },
        { label: 'Publication start', prop: 'publicationStart', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Publication end', prop: 'publicationEnd', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Publication author', prop: 'publicationAuthor', hint: 'User', type: 'dto', fixed: true, source: '/', values: undefined, default: undefined },
        { label: 'Attached publication', prop: 'attachedPublication', hint: 'D t o ref', type: 'dto', fixed: false, source: undefined, values: undefined, default: undefined },
     ],
    _related: [
        { prop: 'children', type: 'unknown', class: 'Unknown', label: 'Children' },
        { prop: 'dependencies', type: 'unknown', class: 'Unknown', label: 'Dependencies' },
        { prop: 'responses', type: 'unknown', class: 'Unknown', label: 'Responses' },
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'Resolved users' },
        { prop: 'resolvedAudience', type: 'unknown', class: 'Unknown', label: 'Resolved audience' },
     ],
    _endpoint: '/api/eda/v2.0/event/v1.0',
    _childrenAt: 'children',
    _docLink: "/assets/wiki/Pillars/PMO/",
    _icon: './assets/dtos/event.svg',
    endpointAPI: new BoundEndpointAPI<Event_v1>('Event_v1', DTO),
    from: (obj: any): Event_v1 => {
        return Object.setPrototypeOf(obj, Event_v1._prototype);
    },
    select: (p: any = '', descendents?: boolean): any => {
        return Event_v1.endpointAPI.get(p, descendents).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Event_v1.from(a) as Event_v1)
            : Event_v1.from(res)
    )}
}
